<template>
<!-- 超管菜单权限 -->
  <div style="padding: 20px;" v-if="show == true">
    <!-- 页头 -->
    <div class="iviewAdmin-layout__page-header" style="margin: 0 !important;">
      <PageHeader title="菜单权限管理" hidden-breadcrumb>
        <template #content>
          <p>菜单权限管理操作须知：右击即可新增、编辑、删除</p>
        </template>
      </PageHeader>
    </div>
    <!-- 内容区 -->
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Row class="ivu-mt" :gutter="12">
        <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="10">
          <Affix :offset-top="110" use-capture>
            <Card dis-hover>
              <template #title>
                <Dropdown @on-click="handleDropdownCheck">
                  <Button type="primary" ghost v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:save`]">
                    添加菜单
                    <Icon type="ios-arrow-down"></Icon>
                  </Button>
                  <template #list>
                    <DropdownMenu>
                      <DropdownItem name="addMenus">添加顶栏菜单</DropdownItem>
                      <DropdownItem name="addSubmenu" divided :disabled="showChildrenButton">添加子菜单</DropdownItem>
                    </DropdownMenu>
                  </template>
                </Dropdown>
                <Button class="l-m-l8" type="primary" ghost @click="handleToggleExpandAll(isExpandAll)">
                  <Icon type="md-list"></Icon>
                  {{ isExpandAll ? "全部展开" : "全部收起" }}
                </Button>
                <Button class="l-m-l8" type="error" ghost v-show="showBatchButton"
                        @click="handleBatchDelete()" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:delete`]">
                  <Icon type="md-list"></Icon>
                  批量删除
                </Button>
              </template>
              <div style="height: 335px;overflow-y: auto;" class="l-custom-scrollbar">
                <Affix>
                  <div class="ivu-p-8">
                    <Input type="text" v-model="searchTreeValue" search autocomplete placeholder="输入菜单名称搜索" />
                  </div>
                </Affix>
                <Tree
                  class="tree-render"
                  :data="treeDataList"
                  :render="handleRenderContent"
                  check-strictly
                  show-checkbox
                  @on-select-change="handleSelectNode"
                  @on-check-change="handleCheckNode"
                  @on-contextmenu="handleContextMenu">
                  <template slot="contextMenu" >
                    <!--<div class="ivu-dropdown-item" @click="handleContextMenuEdit">编辑</div>-->
                    <div class="ivu-dropdown-item" @click="handleSwitchTheParent" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:put`]">切换父级</div>
                    <div class="ivu-dropdown-item" @click="handleContextMenuAddSubmenu" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:save`]">添加子菜单</div>
                    <div class="ivu-dropdown-item" @click="handleContextMenuDelete" style="color: #ed4014"  v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:delete`]">删除</div>
                  </template>
                </Tree>
              </div>
              <Spin size="large" fix v-if="showTreeDataListSpin"></Spin>
            </Card>
          </Affix>
        </Col>
        <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="14">
          <Card dis-hover>
            <template #title>
              <Avatar icon="md-apps" size="small" style="color: rgb(24, 144, 255); background-color: rgb(230, 247, 255);" />
              编辑菜单:
            <!--  {{ formItem.title }}-->
            </template>
            <template #extra>
              <Poptip title="当前编辑切换父级" width="300" placement="bottom-end">
                <a type="text">
                  <Icon type="md-repeat"></Icon>
                  切换父级目录
                </a>
                <template #content>
                  <Cascader :data="parentLevelData" v-model="parent_level" change-on-select transfer @on-visible-change="handleSwitchoverParent" />
                </template>
              </Poptip>
            </template>
            <div v-if="!showForm">
              <Alert>从左侧菜单列表选择一项后，进行编辑</Alert>
            </div>
            <Form
              v-else
              ref="formItem"
              :model="formItem"
              :rules="formItem.menus_type === 0 ? ruleValidate : apiUrlValidate"
              :label-width="110">
              <FormItem label="类型:">
                [{{ formItem.pid == '顶级' ? '顶级' : '子级' }}]侧边栏菜单
              </FormItem>
              <FormItem label="父级:">
                <Breadcrumb>
                  <BreadcrumbItem v-for="(item, index) in formItem.breadcrumb" :key="index">
                    <Icon v-if="item.icon !== ''" :type="item.icon"></Icon> {{item.title}}
                  </BreadcrumbItem>
                </Breadcrumb>
                <!--<Cascader :data="parentLevelData" :value="parent_level" change-on-select />-->
              </FormItem>
              <FormItem label="菜单类型:">
                <RadioGroup v-model="formItem.menus_type">
                  <Radio :label="0">菜单</Radio>
                  <Radio :label="1" >按钮</Radio>
                  <Radio :label="2">接口</Radio>
                </RadioGroup>
              </FormItem>
              <template v-if="formItem.menus_type === 0">
                <FormItem label="打开方式:">
                  <RadioGroup v-model="formItem.target">
                    <Radio :label="0">组件</Radio>
                    <Radio :label="1">内链</Radio>
                    <Radio :label="2">外链</Radio>
                  </RadioGroup>
                </FormItem>
                <FormItem label="标题:" prop="title">
                  <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
                </FormItem>
                <FormItem label="副标题:">
                  <Input v-model="formItem.subhead" placeholder="请输入路由副标题，选填" />
                </FormItem>
                <FormItem label="图标:">
                  <!--<IconTools v-model="formItem.icon" />-->
                  <ele-icon-picker
                      v-model="formItem.icon"
                      placeholder="请选择菜单图标"/>
                </FormItem>
                <FormItem>
                  <template #label>
                    {{ formItem.target === 2 ? '外链地址' : '路由地址' }}
                    <Tooltip v-if="formItem.target === 2" content="需要以`http://`、`https://`、`//`开头" placement="bottom">
                      <Icon type="md-help-circle" />
                    </Tooltip>
                  </template>
                  <Input v-model="formItem.path" :placeholder="formItem.target === 2 ? '请输入外链地址, 必填' : '请输入路由地址, 必填'">
                    <template #prepend>
                      <span>{{$config.routePre}}</span>
                    </template>
                  </Input>
                </FormItem>
                <FormItem>
                  <template #label>
                    {{ formItem.target === 1 ? ' 内链地址:' : ' 组件路径:' }}
                    <Tooltip v-if="formItem.target === 1" content="需要以`http://`、`https://`、`//`开头" placement="bottom">
                      <Icon type="md-help-circle" />
                    </Tooltip>
                  </template>
                  <Input v-model="formItem.component" :disabled="formItem.type === 1 || formItem.target === 2" :placeholder="formItem.target === 1 ? '请输入内链地址' : '请输入路由组件路径，必填'" />
                </FormItem>
                <FormItem>
                  <template #label>
                    权限节点:
                    <Tooltip max-width="250" content="勾选后系统默认创建所需必备的子节点，其它需手动添加" placement="bottom">
                      <Icon type="md-help-circle" />
                    </Tooltip>
                  </template>
                  <Select
                    v-model="formItem.permissions_nodes"
                    :disabled="formItem.menus_type !== 0 || formItem.menus_type !== 2"
                    multiple
                    :max-tag-count="4">
                    <Option v-for="item in permissionsNodesData" :value="item.value" :key="item.value">{{ item.title }}</Option>
                  </Select>
                </FormItem>
              </template>
              <template v-if="formItem.menus_type === 1">
                <FormItem label="按钮名称:" prop="title">
                  <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
                </FormItem>
                <FormItem label="接口地址:" prop="api_url">
                  <Input v-model="formItem.api_url" placeholder="请输入路由标题，必填" />
                </FormItem>
              </template>
              <template  v-if="formItem.menus_type === 2">
                <FormItem label="接口名称:" prop="title">
                  <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
                </FormItem>
                <FormItem label="接口地址:" prop="api_url">
                  <Input v-model="formItem.api_url" placeholder="请输入路由标题，必填" />
                </FormItem>
                <FormItem label="请求方式:" prop="methods">
                  <Select v-model="formItem.methods" clearable placeholder="请选择接口的请求方式">
                    <Option value="GET" label="GET">
                      <span>GET</span>
                      <span style="float:right;color:#ccc">获取请求</span>
                    </Option>
                    <Option value="POST" label="POST">
                      <span>POST</span>
                      <span style="float:right;color:#ccc">表单请求</span>
                    </Option>
                    <Option value="PUT" label="PUT">
                      <span>PUT</span>
                      <span style="float:right;color:#ccc">更新请求</span>
                    </Option>
                    <Option value="DELETE" label="DELETE">
                      <span>DELETE</span>
                      <span style="float:right;color:#ccc">删除请求</span>
                    </Option>
                  </Select>
                </FormItem>
              </template>
              <FormItem label="排序:" prop="sort">
                <Input type="number" v-model.number="formItem.sort" :disabled="formItem.target === 1" placeholder="请输入排序，必填" />
              </FormItem>
              <template v-if="formItem.menus_type === 0">
                <FormItem prop="unique_auth">
                  <template #label>
                    权限标识:
                    <Tooltip max-width="250" content="此配置必须保证权限标识是唯一性, 格式：(system:{自定义}:list{可自定义例如save等})" placement="bottom">
                      <Icon type="md-help-circle" />
                    </Tooltip>
                  </template>
                  <Input v-model="formItem.unique_auth" placeholder="请输入标识，必填">
                    <template #prepend>
                      <span>{{$config.uniquePrefix}}</span>
                    </template>
                  </Input>
                </FormItem>
              </template>
              <template v-if="formItem.menus_type === 1 || formItem.menus_type === 2">
                <FormItem prop="unique_auth">
                  <template #label>
                    权限标识:
                    <Tooltip max-width="250" content="此配置必须保证权限标识是唯一性, 格式：(system:{自定义}:list{可自定义例如save等})" placement="bottom">
                      <Icon type="md-help-circle" />
                    </Tooltip>
                  </template>
                  <Input v-model="formItem.unique_auth" placeholder="请输入标识，必填">
                    <template #prepend>
                      <span>{{$config.uniquePrefix}}</span>
                    </template>
                  </Input>
                </FormItem>
              </template>
              <!-- <FormItem label="菜单归属:">
                <RadioGroup v-model="formItem.affiliation">
                  <Radio label="facilitator">服务商</Radio>
                  <Radio label="system">系统</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="是否可见:">
                <RadioGroup v-model="formItem.hide">
                  <Radio :label="0">显示</Radio>
                  <Radio :label="1">隐藏</Radio>
                </RadioGroup>
              </FormItem> -->
              <FormItem label="状态:">
                <RadioGroup v-model="formItem.status"><Radio :label="0">正常</Radio><Radio :label="1">禁用</Radio></RadioGroup>
              </FormItem>
              <template v-if="formItem.menus_type === 0">
                <FormItem label="备注:">
                  <Input v-model="formItem.note" type="textarea" :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入备注" />
                </FormItem>
              </template>
              <FormItem>
                <Button type="primary" @click="handleSubmit('formItem')" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:put`]">提交</Button>
                <Button style="margin-left: 8px" type="primary" @click="pohuai('formItem')" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:put`]">破坏</Button>
                <Button style="margin-left: 8px" @click="handleReset('formItem')" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:put`]">重置</Button>
              </FormItem>
            </Form>
            <Spin size="large" fix v-if="formItemSpinShow"></Spin>
          </Card>
        </Col>
      </Row>
    </Card>

    <!-- 抽屉：添加编辑顶栏 -->
    <edit-menus
      ref="editMenus"
      :title="showFormItemDrawerTitle"
      :menus-data="currentFormData"
      :parent-level-data="parentLevelData"
      :parent-level="parent_level">
    </edit-menus>

    <div class="l-m-b30"></div>
  </div>
</template>

<script>
import _ from 'lodash'
// import { resolveComponent } from 'vue'
import { Select, Option } from 'view-design'
import EditMenus from './editMenus.vue'

import {
  getsuperman_menus,
  getsuperman_menusDetails,
  editsuperman_menus,
  superman_menuschaoji, menudestroysuperman_menus
} from '@/api/system'
import { menusTreeFormat } from '@/utils'

// ele-admin图标
import EleIconPicker from 'ele-admin/packages/ele-icon-picker';

export default {
  name: 'index',
  components: {
    EditMenus,
    EleIconPicker
  },
  data () {
    return {
      // 属性节点模糊搜索器
      searchTreeValue: '',
      // 是否可以添加子级菜单
      showChildrenButton: true,
      // 全部收起
      isExpandAll: true,
      // 控制批量删除按钮显示
      showBatchButton: false,
      // 树形数据加载参数
      showTreeDataListSpin: false,
      // 树形数据
      treeData: [],
      // 选中的节点数据
      selectedNodeData: [],
      // 右击菜单数据
      contextData: null,
      // 控制提示和表单的显示
      showForm: false,
      // 菜单类型
      menusType: 'menus',
      // 表单数据
      formItem: {
        pid: 0,
        title: '',
        subhead: '',
        icon: '',
        path: '',
        api_url: '',
        methods: '',
        component: '',
        unique_auth: '',
        permissions_nodes: [],
        sort: 0,
        target: 0,
        status: 0,
        is_show: 0,
        note: '',
        menus_type: 0,
        breadcrumb: []
      },
      // 表单验证规则
      ruleValidate: {
        title: [
          { required: true, message: '请输入菜单标题名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', type: 'number', trigger: 'blur' }
        ],
        unique_auth: [
          { required: true, message: '请输入权限标识', trigger: 'blur' }
        ]
      },
      apiUrlValidate: {
        title: [
          { required: true, message: '请输入菜单标题名称', trigger: 'blur' }
        ],
        api_url: [
          { required: true, message: '请输入接口地址', trigger: 'blur' }
        ],
        methods: [
          { required: true, message: '请选择接口请求方式', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入排序', type: 'number', trigger: 'blur' }
        ],
        unique_auth: [
          { required: true, message: '请输入权限标识', trigger: 'blur' }
        ]
      },
      // 当前编辑数据
      currentFormData: {},

      // 父级
      parentLevelData: [],
      parent_level: [],

      // 表单加载效果
      formItemSpinShow: false,
      // 表单抽屉
      showFormItemDrawerTitle: '添加顶栏菜单',
      // 权限节点数据
      permissionsNodesData: [
        {
          title: '列表',
          value: 7,
          checked: false
        },
        {
          title: '添加',
          value: 6,
          checked: false
        },
        {
          title: '修改',
          value: 5,
          checked: false
        },
        {
          title: '删除',
          value: 4,
          checked: false
        },
        {
          title: '详情',
          value: 3,
          checked: false
        },
        {
          title: '批量删除',
          value: 1,
          checked: false
        }
      ],

      // 删除模态框
      deleteModal: true,
      affixInit: false,
      // 选中的当前Menusid
      menusId: 0,
      menusIds: [],

      show:true,
    }
  },
  computed: {
    treeDataList () {
      if (this.searchTreeValue == '') {
        return this.treeData
      }
      return this.treeData.filter(element => {
        if (element.children && element.children.length) {
          return element.children.filter(elementChildren => {
            return elementChildren.title.indexOf(this.searchTreeValue) != -1
          })
        } else {
          console.log(element.title.indexOf(this.searchTreeValue) != -1)
          return element.title.indexOf(this.searchTreeValue) != -1
        }
      })
    }
  },
  watch:{
    '$route':'getPath'
  },
  mounted () {
    this.getPath();
    // this.getMenusListRequest()
  },
  methods: {
    // 每次进入页面都会触发的方法
    getPath(){
      console.log(this.$route.path);
      this.show = false;
      if(this.$route.path == '/system/supermanMenus'){
        this.getMenusListRequest();
        this.show = true;
        console.log('每次都会调用');
      }
    },

    initializes () {
      this.parentLevelData = []
      this.parent_level = []
      this.menusId = 0
      this.currentFormData = {}
      this.formItem = {}
    },
    // 获取侧边栏菜单数据
    getMenusListRequest () {
      this.showTreeDataListSpin = true
      getsuperman_menus().then(res => {
        this.treeData = menusTreeFormat(res.data)
        this.showTreeDataListSpin = false
        this.$refs.editMenus.showFormItemDrawer = false
        this.showForm = false
      }).catch(err => {
        this.$Message.error(err.msg)
      })
    },
    // 获取联级数据
    getMenusCascaderRequest (parentId) {
      superman_menuschaoji(parentId).then(res => {
        console.log(res,'数据')
        this.parentLevelData = res.data.list
        this.parent_level = res.data.data
      }).catch(err => {
        console.log('获取联级数据失败：' + err)
      })
    },
    // 处理渲染树形格式样式
    handleRenderContent (h, { root, node, data }) {
      // 状态判断
      let menusStatus
      if (data.status) {
        menusStatus = h('Tag', {
          color: 'error'
        }, '禁用')
      } else {
        menusStatus = h('Tag', {
          props: {
            color: 'primary'
          }
        }, '正常')
      }
      
      // 按钮类型
      let menusType
      if (data.menus_type === 0) {
        menusType = h('Tag', {
          props: {
            color: 'cyan'
          }
        }, '菜单')
      } else if (data.menus_type === 1) {
        menusType = h('Tag', {
          props: {
            color: 'green'
          }
        }, '按钮')
      } else {
        menusType = h('Tag', {
          props: {
            color: 'orange'
          }
        }, '接口')
      }

      return h('div', {
        class: 'treess',
        style: {
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }
      }, [
        h('div', {
          className: 'ivu-mr-16',
          style: {
          }
        }, [
          h('Icon', {
            props: {
              type: data.icon,
              size: '20'
            }
          }),
          h('span', data.title)
        ]),
        h('Tooltip', {
          props: {
            content: `排序:${data.sort}`,
            placement: 'top',
            transfer: true
          }
        }, [
          h('div', {
            style: {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '20px'
            }
          }, [
            h('div', {
              class: 'l-m-r3'
            }, '状态:'),
            menusStatus,
            h('Divider', {
              props: {
                type: 'vertical'
              }
            }),
            h('div', {
              class: 'l-m-r3'
            }, '类型:'),
            menusType
          ])
        ])
      ])
    },
    // 处理添加顶栏事件 抽屉
    handleDropdownCheck (name) {
      switch (name) {
        case 'addMenus':
          this.currentFormData = Object.assign({}, null)
          this.$refs.editMenus.showFormItemDrawer = true
          this.getMenusCascaderRequest(0)
          break
        case 'addSubmenu':
          this.showFormItemDrawerTitle = '添加子菜单'
          this.currentFormData = Object.assign({
            pid: this.menusId
          }, {})
          this.getMenusCascaderRequest(this.menusId)
          this.$refs.editMenus.showFormItemDrawer = true
          break
      }
    },
    // 处理树形组件的全部展开 OR 全部收起
    handleToggleExpandAll (e) {
      this.isExpandAll = !e
      this.treeData = this.handleToggleExpandNode(this.treeData, e, [])
    },
    // 批量删除
    handleBatchDelete () {},
    // 处理结构
    handleToggleExpandNode (e = [], t, a) {
      return e.forEach(e => {
        let n = {}
        for (let t in e) {
          t !== 'children' && (n[t] = _.cloneDeep(e[t]))
        }
        n.expand = t
        n.contextmenu = !t
        e.children && e.children.length && (n.children = [])
        a.push(n)
        e.children && this.handleToggleExpandNode(e.children, t, n.children)
      }) || a
    },
    // 处理右键菜单 编辑菜单
    handleContextMenuEdit () {
    },
    // 切换父级
    handleSwitchoverParent (value) {
      console.log(value)
    },
    // 处理切换父级
    handleSwitchTheParent () {
      this.$Modal.confirm({
        render: (h) => {
          return h(Select, {}, [
            h(Option)
          ])
        }
      })
    },
    // 处理点击树节点时事件
    handleSelectNode (obj) {
      this.selectedNodeData = obj
      if (obj.length > 0) {
        this.menusId = obj[0].id
        this.formItemSpinShow = true
        this.showForm = true
        this.getMenusCascaderRequest(obj[0].pid)
        getsuperman_menusDetails(obj[0].id).then(res => {
          let data = res.data
          this.formItemSpinShow = false
          this.formItem = data
        }).catch(err => {
          this.$Message.error(err.msg)
        })
        this.showChildrenButton = false
      } else {
        this.showChildrenButton = true
        this.formItem = {}
        this.menusIds.push(obj[0].id)
        this.showForm = false
      }
    },
    // 处理点击复选框时事件
    handleCheckNode (obj) {
      if (obj.length > 0) {
        this.showBatchButton = true
      } else {
        this.showBatchButton = false
      }
    },
    // 处理菜单列表右击事件
    handleContextMenu (data) {
      this.showFormItemDrawerTitle = '添加子菜单'
      this.contextData = data
    },
    // 处理右键菜单 添加子菜单
    handleContextMenuAddSubmenu (data) {
      this.showFormItemDrawerTitle = '添加子菜单: ' + this.contextData.title
      this.currentFormData = Object.assign({
        pid: this.contextData.id
      }, {})
      this.getMenusCascaderRequest(this.contextData.id)
      this.$refs.editMenus.showFormItemDrawer = true
    },
    // 处理右键菜单 删除
    handleContextMenuDelete () {
      let delfromData = {
        title: this.contextData.title,
        url: `/system/superman_menus/${this.contextData.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.getMenusListRequest()
        // this.getMenusUnique();
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理提交保存事件
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          editsuperman_menus(this.formItem).then(res => {
            this.$Message.success(res.msg)
            this.getMenusListRequest()
            this.reload()
            this.$store.dispatch('admin/getAdminInfo')
          }).catch(err => {
            this.$Message.error(err.msg || '保存失败')
          })
        } else {
          this.$Message.error('Fail!')
        }
      })
    },
    //破坏
    pohuai(name){
      menudestroysuperman_menus(this.formItem.id).then(res => {
        console.log(res)
        this.getMenusListRequest()
        this.reload()
      })
    },
    // 处理重置表单事件
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.initAffix, true)
  },
  inject: ['reload']
}
</script>

<style scoped>
::v-deep .treess  {
  display: flex;
}
</style>
